import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/orders_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleOrder, useUpdateOrder } from "../../shared/hooks/UseOrder";
import { URI } from "../../domain/constant";
import { convertToFormData } from "../../shared/upload";
const ViewOrder = ({ match }) => {
  const [data] = useSingleOrder(match.params.id);
  const [updateData] = useUpdateOrder();
  const { order_loading, order } = data;
  const [featuredImage, setFeaturedImage] = useState(null);

  const markPaid = async (is_paid) => {
    const values = { is_paid: is_paid };
    const data = await convertToFormData({ values, featuredImage });
    await updateData(order._id, data);
  };

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!order_loading ? (
          order && (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h4 className="card-title">
                            {" "}
                            Order: #{order.order_id}{" "}
                          </h4>
                        </div>
                        <div>
                          {!order.is_paid && (
                            <button
                              className="btn btn-success m-1"
                              onClick={() => markPaid(true)}
                            >
                              {" "}
                              <i className="fa fa-check"></i> Mark as Paid{" "}
                            </button>
                          )}

                          <button className="btn btn-secondary">
                            {order.payment_method}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th> # </th>
                            <th> Product </th>
                            <th> Qty </th>
                            <th> Total </th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.products &&
                            order.products.map((item, index) => {
                              return (
                                <tr>
                                  <td> {index + 1} </td>
                                  <td>
                                    {" "}
                                    <img
                                      src={`${URI}${item.image}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "contain",
                                      }}
                                    />{" "}
                                    {item.name}{" "}
                                  </td>
                                  <td>{item.quantity}</td>
                                  <td>{item.quantity * item.sale_price}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Customer</h4>
                        </div>
                        <div className="card-body">
                          <p>
                            {" "}
                            <strong> Name : </strong> {order.first_name}{" "}
                            {order.last_name}
                          </p>
                          <p>
                            {" "}
                            <strong> Phone : </strong> {order.phone}{" "}
                          </p>
                          <p>
                            {" "}
                            <strong> Email : </strong> {order.email}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Address</h4>
                        </div>
                        <div className="card-body">
                          <p>
                            {" "}
                            <strong> Address : </strong> {order.address}{" "}
                            {order.address_2}
                          </p>
                          <p>
                            {" "}
                            <strong> Landmark : </strong> {order.landmark}{" "}
                          </p>
                          <p>
                            {" "}
                            <strong> City : </strong> {order.city}{" "}
                          </p>
                          <p>
                            {" "}
                            <strong> State : </strong> {order.state}{" "}
                          </p>
                          <p>
                            {" "}
                            <strong> Pincode : </strong> {order.pincode}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {" "}
                        Order Details{" "}
                        {!order.is_paid ? (
                          <button className="badge bg-danger text-light">
                            UNPAID{" "}
                          </button>
                        ) : (
                          <button className="badge bg-success text-light">
                            {" "}
                            PAID{" "}
                          </button>
                        )}
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <strong> Sub Total </strong>
                        </div>
                        <div> ₹{order.sub_total} </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <strong> Delivery Charges </strong>
                        </div>
                        <div> ₹{order.delivery_charges} </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <strong> Discount </strong>
                        </div>
                        <div> ₹{order.discount} </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <div>
                          <strong> Total </strong>
                        </div>
                        <div> ₹{order.total} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewOrder;
