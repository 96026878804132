export const PAGE_TITLE = "Banners";
export const PAGE_SINGLE_TITLE = "Banner";
export const LINK_URL = "banners";
export const inputFields = {
  link: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "",
    options: "",
    field: "",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  link: "",
};

export const view_all_table = [
  { name: "Link", value: "link" },
  { name: "Image", value: "image", image: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "link",
    field: "link",
    label: "Link",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
