import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { banner_reducer } from "./banner_reducer";
import { blog_reducer } from "./blog_reducer";
import { blogcategory_reducer } from "./blogcategory_reducer";
import { category_reducer } from "./category_reducer";
import { coupon_reducer } from "./coupon_reducer";
import { customer_reducer } from "./customer_reducer";
import { contact_reducer } from "./contact_reducer";
import { newsletter_reducer } from "./newsletter_reducer";
import { offer_reducer } from "./offer_reducer";
import { order_reducer } from "./order_reducer";
import { product_reducer } from "./product_reducer";
import { returnrequest_reducer } from "./returnrequest_reducer";

export default combineReducers({
  alert,
  auth,
  banner: banner_reducer,
  blog: blog_reducer,
  blogcategory: blogcategory_reducer,
  category: category_reducer,
  coupon: coupon_reducer,
  customer: customer_reducer,
  newsletter: newsletter_reducer,
  offer: offer_reducer,
  order: order_reducer,
  product: product_reducer,
  returnrequest: returnrequest_reducer,
  contact: contact_reducer,
});
