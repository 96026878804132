export const PAGE_TITLE = "Blogs";
export const PAGE_SINGLE_TITLE = "Blog";
export const LINK_URL = "blogs";
export const inputFields = {
  title: {
    type: "string",
    required: true,
    title: "Title",
    inputType: "",
    options: "",
    field: "",
    slug: true,
  },
  category: {
    type: "related",
    required: false,
    title: "Category",
    inputType: "",
    options: "",
    field: "name",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
    inputType: "",
    options: "",
    field: "",
  },

  blog_date: {
    type: "string",
    required: false,
    title: "Blog Date",
    inputType: "date",
    options: "",
    field: "",
  },
  description: {
    type: "html",
    required: false,
    title: "Description",
    inputType: "",
    options: "",
    field: "",
  },
  slug: {
    type: "string",
    required: true,
    title: "Slug",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  title: "",
  slug: "",
  blog_date: "",
  description: "",
  category: "",
};

export const view_all_table = [
  { name: "Title", value: "title" },
  { name: "Image", value: "image", image: true },
  { name: "Category", value: "category", related: true, field: "name" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "category",
    field: "category",
    label: "Category",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
];
