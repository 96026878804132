export const PAGE_TITLE = "Contacts";
export const PAGE_SINGLE_TITLE = "Contact";
export const LINK_URL = "contacts";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
  },
  subject: {
    type: "string",
    required: true,
    title: "Subject",
  },
  message: {
    type: "text",
    required: true,
    title: "Message",
  },
};
export const initialValues = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

export const view_all_table = [
  { name: "name", value: "name" },
  { name: "email", value: "email" },
  { name: "phone", value: "phone" },
  { name: "subject", value: "subject" },
  { name: "message", value: "message" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
