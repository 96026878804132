import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addProduct,
  getProducts,
  getProduct,
  editProduct,
  deleteProduct,
  getAllProducts,
} from "../../store/actions/product_action";
import _debounce from "lodash/debounce";
// import { useSelectAllProduct } from "./UseProduct";
import { useSelectAllCategory } from "./UseCategory";

// Get All Data
export const useAllProducts = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteProduct(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getProducts({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    if (pageNumber) {
      dispatch(
        getProducts({
          pageNumber,
        })
      );
    }
  }, [pageNumber]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleProduct = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getProduct(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateProduct = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.product);
  const addData = async (data) => {
    await dispatch(addProduct(data));
  };
  return [data, addData];
};
export const useUpdateProduct = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.product);
  const updateData = async (id, data) => {
    await dispatch(editProduct(id, data));
  };
  return [updateData];
};

export const useSelectAllProduct = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getAllProducts({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [product, setProductSearchField, setProductSearchValue] =
    useSelectAllProduct();
  const [category, setCategorySearchField, setCategorySearchValue] =
    useSelectAllCategory();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (product && product.all_products) {
      const newData = product.all_products.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        up_sell_products: { product: newData },
        cross_sell_products: { product: newData },
        grouped_products: { product: newData },
      });
    }
  }, [product]);
  useEffect(() => {
    if (category && category.all_categorys) {
      const newData = category.all_categorys.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        categories: { category: newData },
      });
    }
  }, [category]);

  return [
    dropdownOptions,
    // setClientSearchField, setClientSearchValue
  ];
};
