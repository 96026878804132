export const PAGE_TITLE = "Return Requests";
export const PAGE_SINGLE_TITLE = "Return Request";
export const LINK_URL = "returnrequests";
export const inputFields = {
  order: {
    type: "related",
    required: true,
    title: "Order",
    inputType: "",
    options: "",
    field: "order_id",
  },
  customer: {
    type: "related",
    required: true,
    title: "Customer",
    inputType: "",
    options: "",
    field: "name",
  },
  date: {
    type: "string",
    required: true,
    title: "Date",
    inputType: "date",
    options: "",
    field: "",
  },
  reason: {
    type: "text",
    required: false,
    title: "Reason",
    inputType: "",
    options: "",
    field: "",
  },
  description: {
    type: "text",
    required: false,
    title: "Description",
    inputType: "",
    options: "",
    field: "",
  },
  notes: {
    type: "text",
    required: false,
    title: "Notes",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  order: "",
  customer: "",
  date: "",
  reason: "",
  description: "",
  notes: "",
};

export const view_all_table = [
  { name: "Order", value: "order", related: true, field: "order_id" },
  { name: "Customer", value: "customer", related: true, field: "name" },
  { name: "Date", value: "date", date: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "order",
    field: "order",
    label: "Order",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "customer",
    field: "customer",
    label: "Customer",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "order_from",
    field: "createdAt",
    label: "From Date",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$gte",
  },
  {
    id: "order_to",
    field: "createdAt",
    label: "To Date",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$lte",
  },
];
