export const PAGE_TITLE = "Categorys";
export const PAGE_SINGLE_TITLE = "Category";
export const LINK_URL = "categorys";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "",
    options: "",
    field: "",
    slug: true,
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
    inputType: "",
    options: "",
    field: "",
  },

  parent_category: {
    type: "related",
    required: false,
    title: "Parent Category",
    inputType: "",
    options: "",
    field: "name",
  },
  description: {
    type: "text",
    required: false,
    title: "Description",
    inputType: "",
    options: "",
    field: "",
  },
  published_date: {
    type: "string",
    required: false,
    title: "Published Date",
    inputType: "date",
    options: "",
    field: "",
  },
  slug: {
    type: "slug",
    required: true,
    title: "slug",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  name: "",
  parent_category: "",
  description: "",
  published_date: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Image", value: "image", image: true },
  {
    name: "Parent Category",
    value: "parent_category",
    related: true,
    field: "name",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
