import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/app.min.css";
import "./assets/css/custom.css";
import "./assets/css/wysiwyg.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Login from "./containers/login/Login";
import { loadUser } from "./store/actions/auth";
import setAuthToken from "./domain/setAuthToken";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import Dashboard from "./containers/dashboard/Dashboard";
import PageNotFound from "./containers/notfound/PageNotFound";

import Profile from "./containers/profile/Profile";

// import setAuthToken from "./domain/setAuthToken";

import AddBanner from "./containers/banners/AddBanner";
import AllBanners from "./containers/banners/AllBanners";
import ViewBanner from "./containers/banners/ViewBanner";
import EditBanner from "./containers/banners/EditBanner";

import AddBlogcategory from "./containers/blogcategorys/AddBlogcategory";
import AllBlogcategorys from "./containers/blogcategorys/AllBlogcategorys";
import ViewBlogcategory from "./containers/blogcategorys/ViewBlogcategory";
import EditBlogcategory from "./containers/blogcategorys/EditBlogcategory";

import AddBlog from "./containers/blogs/AddBlog";
import AllBlogs from "./containers/blogs/AllBlogs";
import ViewBlog from "./containers/blogs/ViewBlog";
import EditBlog from "./containers/blogs/EditBlog";

import AddCategory from "./containers/categorys/AddCategory";
import AllCategorys from "./containers/categorys/AllCategorys";
import ViewCategory from "./containers/categorys/ViewCategory";
import EditCategory from "./containers/categorys/EditCategory";

import AddContact from "./containers/contacts/AddContact";
import AllContacts from "./containers/contacts/AllContacts";
import ViewContact from "./containers/contacts/ViewContact";
import EditContact from "./containers/contacts/EditContact";

import AddCoupon from "./containers/coupons/AddCoupon";
import AllCoupons from "./containers/coupons/AllCoupons";
import ViewCoupon from "./containers/coupons/ViewCoupon";
import EditCoupon from "./containers/coupons/EditCoupon";

import AddCustomer from "./containers/customers/AddCustomer";
import AllCustomers from "./containers/customers/AllCustomers";
import ViewCustomer from "./containers/customers/ViewCustomer";
import EditCustomer from "./containers/customers/EditCustomer";

import AddNewsletter from "./containers/newsletters/AddNewsletter";
import AllNewsletters from "./containers/newsletters/AllNewsletters";
import ViewNewsletter from "./containers/newsletters/ViewNewsletter";
import EditNewsletter from "./containers/newsletters/EditNewsletter";

import AddOffer from "./containers/offers/AddOffer";
import AllOffers from "./containers/offers/AllOffers";
import ViewOffer from "./containers/offers/ViewOffer";
import EditOffer from "./containers/offers/EditOffer";

import AddOrder from "./containers/orders/AddOrder";
import AllOrders from "./containers/orders/AllOrders";
import ViewOrder from "./containers/orders/ViewOrder";
import EditOrder from "./containers/orders/EditOrder";

import AddProduct from "./containers/products/AddProduct";
import AllProducts from "./containers/products/AllProducts";
import ViewProduct from "./containers/products/ViewProduct";
import EditProduct from "./containers/products/EditProduct";

import AddReturnrequest from "./containers/returnrequests/AddReturnrequest";
import AllReturnrequests from "./containers/returnrequests/AllReturnrequests";
import ViewReturnrequest from "./containers/returnrequests/ViewReturnrequest";
import EditReturnrequest from "./containers/returnrequests/EditReturnrequest";

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem("token");
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path="/" component={Login} />

          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/profile" component={Profile} />

          <PrivateRoutes exact path="/banners" component={AllBanners} />
          <PrivateRoutes exact path="/banners/add" component={AddBanner} />
          <PrivateRoutes
            exact
            path="/banners/:id/view"
            component={ViewBanner}
          />
          <PrivateRoutes
            exact
            path="/banners/:id/edit"
            component={EditBanner}
          />

          <PrivateRoutes
            exact
            path="/blogcategorys"
            component={AllBlogcategorys}
          />
          <PrivateRoutes
            exact
            path="/blogcategorys/add"
            component={AddBlogcategory}
          />
          <PrivateRoutes
            exact
            path="/blogcategorys/:id/view"
            component={ViewBlogcategory}
          />
          <PrivateRoutes
            exact
            path="/blogcategorys/:id/edit"
            component={EditBlogcategory}
          />

          <PrivateRoutes exact path="/blogs" component={AllBlogs} />
          <PrivateRoutes exact path="/blogs/add" component={AddBlog} />
          <PrivateRoutes exact path="/blogs/:id/view" component={ViewBlog} />
          <PrivateRoutes exact path="/blogs/:id/edit" component={EditBlog} />

          <PrivateRoutes exact path="/categorys" component={AllCategorys} />
          <PrivateRoutes exact path="/categorys/add" component={AddCategory} />
          <PrivateRoutes
            exact
            path="/categorys/:id/view"
            component={ViewCategory}
          />
          <PrivateRoutes
            exact
            path="/categorys/:id/edit"
            component={EditCategory}
          />

          <PrivateRoutes exact path="/contacts" component={AllContacts} />
          <PrivateRoutes exact path="/contacts/add" component={AddContact} />
          <PrivateRoutes
            exact
            path="/contacts/:id/view"
            component={ViewContact}
          />
          <PrivateRoutes
            exact
            path="/contacts/:id/edit"
            component={EditContact}
          />
          <PrivateRoutes exact path="/coupons" component={AllCoupons} />
          <PrivateRoutes exact path="/coupons/add" component={AddCoupon} />
          <PrivateRoutes
            exact
            path="/coupons/:id/view"
            component={ViewCoupon}
          />
          <PrivateRoutes
            exact
            path="/coupons/:id/edit"
            component={EditCoupon}
          />

          <PrivateRoutes exact path="/customers" component={AllCustomers} />
          <PrivateRoutes exact path="/customers/add" component={AddCustomer} />
          <PrivateRoutes
            exact
            path="/customers/:id/view"
            component={ViewCustomer}
          />
          <PrivateRoutes
            exact
            path="/customers/:id/edit"
            component={EditCustomer}
          />

          <PrivateRoutes exact path="/newsletters" component={AllNewsletters} />
          <PrivateRoutes
            exact
            path="/newsletters/add"
            component={AddNewsletter}
          />
          <PrivateRoutes
            exact
            path="/newsletters/:id/view"
            component={ViewNewsletter}
          />
          <PrivateRoutes
            exact
            path="/newsletters/:id/edit"
            component={EditNewsletter}
          />

          <PrivateRoutes exact path="/offers" component={AllOffers} />
          <PrivateRoutes exact path="/offers/add" component={AddOffer} />
          <PrivateRoutes exact path="/offers/:id/view" component={ViewOffer} />
          <PrivateRoutes exact path="/offers/:id/edit" component={EditOffer} />

          <PrivateRoutes exact path="/orders" component={AllOrders} />
          <PrivateRoutes exact path="/orders/add" component={AddOrder} />
          <PrivateRoutes exact path="/orders/:id/view" component={ViewOrder} />
          <PrivateRoutes exact path="/orders/:id/edit" component={EditOrder} />

          <PrivateRoutes exact path="/products" component={AllProducts} />
          <PrivateRoutes exact path="/products/add" component={AddProduct} />
          <PrivateRoutes
            exact
            path="/products/:id/view"
            component={ViewProduct}
          />
          <PrivateRoutes
            exact
            path="/products/:id/edit"
            component={EditProduct}
          />

          <PrivateRoutes
            exact
            path="/returnrequests"
            component={AllReturnrequests}
          />
          <PrivateRoutes
            exact
            path="/returnrequests/add"
            component={AddReturnrequest}
          />
          <PrivateRoutes
            exact
            path="/returnrequests/:id/view"
            component={ViewReturnrequest}
          />
          <PrivateRoutes
            exact
            path="/returnrequests/:id/edit"
            component={EditReturnrequest}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
