export const PAGE_TITLE = "Orders";
export const PAGE_SINGLE_TITLE = "Order";
export const LINK_URL = "orders";
export const inputFields = {
  first_name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [
  { name: "Order Date", value: "createdAt", date: true },
  { name: "Is Paid?", value: "is_paid", boolean: true },

  { name: "Order ID", value: "order_id" },
  { name: "Name", value: "first_name" },
  { name: "Phone", value: "phone" },
  { name: "Email", value: "email" },
  { name: "Total Amount", value: "total", preFilled: "₹" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "first_name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "phone",
    field: "phone",
    label: "Phone",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
