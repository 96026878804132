export const PAGE_TITLE = "Products";
export const PAGE_SINGLE_TITLE = "Product";
export const LINK_URL = "products";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "",
    options: "",
    field: "",
    slug: true,
  },
  short_description: {
    type: "html",
    required: false,
    title: "Short Description",
    inputType: "",
    options: "",
    field: "",
  },
  categories: {
    type: "array",

    title: "Category",
    inputType: "",
    options: "",
    fields: {
      category: {
        type: "related",
        required: true,
        title: "Category",
        inputType: "",
        options: "",
        field: "name",
      },
    },
  },
  regular_price: {
    type: "string",
    required: true,
    title: "Regular Price",
    inputType: "number",
    options: "",
    field: "",
  },
  sale_price: {
    type: "string",
    required: true,
    title: "Sale Price",
    inputType: "number",
    options: "",
    field: "",
  },
  tax: {
    type: "string",
    required: false,
    title: "Tax",
    inputType: "number",
    options: "",
    field: "",
  },
  width: {
    type: "string",
    required: false,
    title: "Width",
    inputType: "",
    options: "",
    field: "",
  },
  height: {
    type: "string",
    required: false,
    title: "Height",
    inputType: "",
    options: "",
    field: "",
  },
  length: {
    type: "string",
    required: false,
    title: "Length",
    inputType: "",
    options: "",
    field: "",
  },
  description: {
    type: "html",
    required: false,
    title: "Description",
    inputType: "",
    options: "",
    field: "",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
    inputType: "",
    options: "",
    field: "",
  },
  gallery: {
    type: "gallery",
    required: false,
    title: "Gallery",
    inputType: "",
    options: "",
    field: "",
  },
  up_sell_products: {
    type: "array",
    required: false,
    title: "Up Sell Products",
    fields: {
      product: {
        type: "related",
        required: false,
        title: "Product",
        inputType: "",
        options: "",
        field: "name",
      },
    },
  },
  cross_sell_products: {
    type: "array",
    required: false,
    title: "Cross Sell Products",
    fields: {
      product: {
        type: "related",
        required: false,
        title: "Product",
        inputType: "",
        options: "",
        field: "name",
      },
    },
  },
  grouped_products: {
    type: "array",
    required: false,
    title: "Grouped Products",
    fields: {
      product: {
        type: "related",
        required: false,
        title: "Product",
        inputType: "",
        options: "",
        field: "name",
      },
    },
  },
  is_in_stock: {
    type: "checkbox",
    required: false,
    title: "Is In Stock",
    inputType: "",
    options: "",
    field: "",
  },
  stock: {
    type: "string",
    required: false,
    title: "Stock",
    inputType: "",
    options: "",
    field: "",
  },
  slug: {
    type: "slug",
    required: true,
    title: "slug",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  name: "",
  short_description: "",
  categories: [{ category: "" }],
  regular_price: "",
  sale_price: "",
  tax: "",
  width: "",
  height: "",
  length: "",
  description: "",
  // up_sell_products: [{ product: "" }],
  // cross_sell_products: [{ product: "" }],
  // grouped_products: [{ product: "" }],
  is_in_stock: "",
  stock: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Sale Price", value: "sale_price" },
  { name: "Image", value: "image", image: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "order_from",
    field: "createdAt",
    label: "From Date",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$gte",
  },
  {
    id: "order_to",
    field: "createdAt",
    label: "To Date",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$lte",
  },
];
