import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";

const Dashboard = () => {
  return (
    <div className="pace-done">
      <Header />
      <BreadCrumb
        title={"DASHBOARD"}
        mainLinkTitle="Dashboard"
        mainLinkUrl="/dashboard"
        activeLink={"Home"}
      />
      {/* <div className="text-center" style={{ padding: "50px 0px" }}>
        <h1> Coming Soon </h1>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
