import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import BlogForm from "../../components/blogs/BlogForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/blogs_enum";
import { useCreateBlog,useGetDropdownOptions } from "../../shared/hooks/UseBlog";
// import { useSelectAllBlog } from "../../shared/hooks/UseBlog";

const AddBlog = ({}) => {
  let history = useHistory();
  const [blog, addData] = useCreateBlog();
  const { add_blog_loading } = blog;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
 
 

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  const [dropdownOptions] = useGetDropdownOptions();

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
              <p className="card-title-desc">
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>
            <BlogForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
              loading={add_blog_loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
