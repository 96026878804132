export const PAGE_TITLE = "Coupons";
export const PAGE_SINGLE_TITLE = "Coupon";
export const LINK_URL = "coupons";
export const inputFields = {
  code: {
    type: "string",
    required: true,
    title: "Code",
    inputType: "",
    options: "",
  },
  discount_type: {
    type: "select",
    required: true,
    title: "Discount Type",
    inputType: "",
    options: ["PERCENTAGE", "FIXED"],
  },
  max_discount: {
    type: "string",
    required: true,
    title: "Max Discount",
    inputType: "number",
    options: "",
  },
  min_cart_value: {
    type: "string",
    required: true,
    title: "Min Cart Value",
    inputType: "number",
    options: "",
  },
  discount: {
    type: "string",
    required: true,
    title: "Discount",
    inputType: "number",
    options: "",
  },
};
export const initialValues = {
  code: "",
  discount_type: "",
  max_discount: "",
  min_cart_value: "",
  discount: "",
};

export const view_all_table = [
  { name: "Code", value: "code" },
  { name: "Discount type", value: "discount_type" },
  { name: "Max Discount", value: "max_discount" },
  { name: "Min Cart Value", value: "min_cart_value" },
  { name: "Discount", value: "discount" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "code",
    field: "code",
    label: "Code",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "discount_type",
    field: "discount_type",
    label: "Discount Type",
    type: "select",
    search_type: "exact",
    inputType: "text",
    condition: "",
    options: ["PERCENTAGE", "FIXED"],
  },
  {
    id: "max_discount",
    field: "max_discount",
    label: "Max Discount",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "min_cart_value",
    field: "Min Cart Value",
    label: "Min Cart Value",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "discount",
    field: "discount",
    label: "Discount",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
