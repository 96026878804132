export const PAGE_TITLE = "Customers";
export const PAGE_SINGLE_TITLE = "Customer";
export const LINK_URL = "customers";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "",
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
    inputType: "",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
    inputType: "number",
  },
  address: {
    type: "string",
    required: false,
    title: "Address",
    inputType: "",
  },
  password: {
    type: "string",
    required: true,
    title: "Password",
    inputType: "password",
    hideOnView: true,
  },
  city: {
    type: "string",
    required: false,
    title: "City",
    inputType: "",
  },
  state: {
    type: "string",
    required: false,
    title: "State",
    inputType: "",
  },
  pincode: {
    type: "string",
    required: false,
    title: "Pincode",
    inputType: "number",
  },
};
export const initialValues = {
  name: "",
  email: "",
  phone: "",
  address: "",
  password: "",
  city: "",
  state: "",
  pincode: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "Phone", value: "phone" },
  { name: "Address", value: "address" },
  { name: "City", value: "city" },
  { name: "State", value: "state" },
  { name: "Pincode", value: "pincode" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "email",
    field: "email",
    label: "email",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "phone",
    field: "phone",
    label: "phone",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "address",
    field: "address",
    label: "address",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "city",
    field: "city",
    label: "city",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "state",
    field: "state",
    label: "state",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "pincode",
    field: "pincode",
    label: "pincode",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
